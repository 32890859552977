.page-header {
    color: #282828;
    font-family: "TelstraAkkuratBold";
    font-size: 40px;
    font-weight: bold;
    letter-spacing: -0.25px;
    line-height: 52px;

    @media (max-width: 768px) {
        font-size: 34px,
    }
}

.sub-header-parent {
    padding-top: 20px;
}

.sub-header {
    color: rgb(65, 65, 65);
    font-family: "TelstraAkkuratLight";
    font-size: 22px;
    font-weight: 300;
    letter-spacing: -0.42px;
    line-height: 31px;
    min-width: 200px;
}

.icon-bg {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    background: rgb(230, 230, 230);
    text-align: center;
    vertical-align: middle;
}

.help-support-icon {
    fill: #282828;
    width: 85px;
    height: 85px;
}

.help-content {
    display: flex;
    align-items: flex-start;
    dd {
        margin-inline-start: 32px;
    }
}

.info-padding {
    padding-top: 40px;
}

.detail-content {
    padding-left: 20px;
    color: rgb(40, 40, 40);
    font-family: "TelstraAkkuratLight";
    font-size: 16px;
    letter-spacing: -0.1px;
}

.section-title {
    font-family: "TelstraAkkuratBold";
}

.all-enquiries-content {
    margin-top: 25px;
}

.mail-enquiries-content {
    margin-top: 25px;
}

.mail-enquiries-content>svg {
    margin-top: -3px;
}

.inline-anchor {
    color: rgb(0, 100, 210);
    font-family: "TelstraAkkuratRegular";
    font-size: 16px;
    font-weight: normal;
    letter-spacing: -0.3px;
    line-height: 19px;
    text-decoration: underline;

    &:visited {
        color: rgb(0, 100, 210);
    }

    &:active {
        color: rgb(0, 100, 210);
    }
}

.mail-address {
    color: rgb(0, 100, 210);
    font-family: "TelstraAkkuratRegular";
    font-size: 16px;
    font-weight: normal;
    letter-spacing: -0.1px;
    line-height: 22px;
    text-decoration: none;
}