@use '..' as able;

.able-PopoverMenu {
  &--LowEmphasis {
    @include able.PopoverMenu(LowEmphasis);
  }

  &--MediumEmphasis {
    @include able.PopoverMenu(MediumEmphasis);
  }

  &--MediumEmphasisCompact {
    @include able.PopoverMenu(MediumEmphasisCompact);
  }

  &--HighEmphasis {
    @include able.PopoverMenu(HighEmphasis);
  }

  &--HighEmphasisCompact {
    @include able.PopoverMenu(HighEmphasisCompact);
  }

  &--EllipsisIcon {
    @include able.PopoverMenu(EllipsisIcon);
  }
}
