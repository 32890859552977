@use '..' as able;

.able-MessageSection--storybook-width {
  @include able.mediaQuery(breakpointMD) {
    width: 50%;
  }
}

.able-MessageSection {
  &--Attention {
    @include able.MessageSection(Attention);

    &--Interactive {
      @include able.MessageSection(Attention, $interactive: true);
    }
  }

  &--Error {
    @include able.MessageSection(Error);

    &--Interactive {
      @include able.MessageSection(Error, $interactive: true);
    }
  }

  &--Information {
    @include able.MessageSection(Information);

    &--Interactive {
      @include able.MessageSection(Information, $interactive: true);
    }
  }

  &--Success {
    @include able.MessageSection(Success);

    &--Interactive {
      @include able.MessageSection(Success, $interactive: true);
    }
  }

  &--Warning {
    @include able.MessageSection(Warning);

    &--Interactive {
      @include able.MessageSection(Warning, $interactive: true);
    }
  }
}
