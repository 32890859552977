.flex-dl {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;

    dt {
        font-family: "TelstraAkkuratBold";
    }

    dd {
        font-family: "TelstraAkkuratLight";
        //margin-left: 0;
    }
}

.info-grouping {
    min-width: 14rem;

    &>dd {
        display: inline-block;
        position: relative;
        bottom: 6px;
    }

    & dt>svg {
        position: relative;
        top: 8px;
    }
}