$alt-color: #1a64d2;
$base-font-color: #414141;

.search-combobox-container {
  position: relative;
  width: fit-content;
  margin-left: auto;

  label {
    font: 700 1.25rem/1.4 Telstra Akkurat, Helvetica Neue, Helvetica, Arial, sans-serif;
  }

  span {
    display: block;
    font: 400 0.875rem/1.42857143 Telstra Akkurat, Helvetica Neue, Helvetica, Arial, sans-serif;
    letter-spacing: 0.01125rem;
    margin: 0;
    color: #707070;
    padding: 0.25rem 0 0 0;
    max-width: 30ch;
  }
}

.combobox .group {
  display: inline-flex;
  cursor: pointer;
  position: relative;
  width: 100%;
}

.combobox input,
.combobox button,
.combobox .search-icon-container {
  background-color: white;
  color: black;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  border: none;
  border-bottom: 1px solid #757575;
  position: relative;
}

.search-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
}

.combobox input:focus {
  border-bottom: 2px solid $alt-color;

  ~button {
    border-bottom: 2px solid $alt-color;
  }

  ~.search-icon-container {
    border-bottom: 2px solid $alt-color;
  }
}

.combobox input {
  outline: none;
  color: $base-font-color;
  font-family: "TelstraAkkurat-Light";
  font-size: 18px;
  font-weight: normal;
  letter-spacing: -0.15px;
  padding: 12px 0px;
  vertical-align: middle;
  width: 100%;
}

.combobox button {
  display: flex;
  align-items: center;
  cursor: pointer;
}

ul[role="listbox"] {
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 99;
  list-style: none;
  background-color: white;
  box-sizing: border-box;
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  cursor: pointer;
  filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
}

ul[role="listbox"] li[role="option"] {
  margin: 0;
  display: block;
  padding: 0.75rem;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-right: 1px solid transparent;
  border-left: 4px solid transparent;
  font-family: "TelstraAkkurat-Light";
  color: $base-font-color;
  font-size: 18px;
  letter-spacing: -0.15px;
  line-height: 25px;
}

[role="listbox"] [role="option"][aria-selected="true"].test {
  border-top: 1px solid $alt-color;
  border-bottom: 1px solid $alt-color;
  border-right: 1px solid $alt-color;
  border-left: 4px solid $alt-color;
}

[role="listbox"] [role="option"]:hover {
  border-left: 4px solid $alt-color;
}

[role="listbox"] [role="option"][aria-selected="true"].test,
[role="listbox"] [role="option"]:hover {
  font-weight: bold;
  color: $alt-color;
}