.able-MessageInline--Default > svg:first-of-type {
    display: none;
}
.preLinktxt {
    color: rgb(65, 65, 65);
    font-family: "TelstraAkkuratBold";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.1px;
    line-height: 20px;
    padding-right: 15px;
}

.inlineAnchor {
    text-decoration: none;
}

.linkTxt {
    color: rgb(0, 98, 218);
    font-family: "TelstraAkkuratRegular" !important;
    font-size: 16px !important;
    font-weight: normal !important;
    letter-spacing: 0px;
    line-height: 24px;
    text-decoration: none;
    border-bottom: 2px solid rgb(0, 98, 218);
}

.custom-svg-class {
    width: 24px;
    height: 24px;
    fill: rgb(0, 98, 218);
    margin-left: 5px;
    vertical-align: middle;
}

/* Override styles for .able-ActionButton--low-emphasis */
.able-ActionButton--low-emphasis {
    font: 400 1rem Arial, sans-serif !important;
    line-height: 1.5rem !important;
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}