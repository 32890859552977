.service-page-header {
    color: rgb(65, 65, 65);
    font-family: "TelstraAkkuratBold";
    font-size: 40px;
    font-weight: bold;
    letter-spacing: -1.25px;
    @media (max-width: 768px) {
        font-size: 34px,
    }
    margin: 0;
}