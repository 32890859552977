@use '..' as able;

.able-Grid--padding--false {
  @include able.grid(false);
}

.able-Grid,
.able-grid {
  @include able.grid();
}
