@use '..' as able;

.able-TextField {
  @include able.TextField();

  &--Tiny {
    @include able.TextField('Tiny');
  }

  &--Short {
    @include able.TextField('Short');
  }

  &--Default {
    @include able.TextField('Default');
  }

  &--Long {
    @include able.TextField('Long');
  }

  &--MaskInput {
    @include able.MaskInput();
  }
}
