@use '..' as able;

.able-Checkbox {
  @include able.Checkbox();

  &--Comfortable {
    @include able.Checkbox(Comfortable);
  }

  &--Comfortable--Icon {
    @include able.Checkbox(Comfortable, Icon);
  }

  &--Comfortable--Picto {
    @include able.Checkbox(Comfortable, Pictogram);
  }
}
