@use '@able/web/src/index' as able;

.dashboard-container {
    padding: 65px 5%;
}

.external-link {
    padding-bottom: 32px !important;
}

.dashboard-cards-container {
    display: flex;
    gap: 1em;
    flex-wrap: wrap;
    padding: 0;
}