.outage-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.top-flex {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
}

.outages-txt {
    color: rgb(65, 65, 65);
    font-family: "TelstraAkkuratRegular";
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.15px;
    line-height: 22px;
    padding-top: 36px;
}

.outage-link {
    padding-bottom: 0;
}

.outage-link > div{
    margin-bottom: -8px;
}

.outage-link-padding {
    padding-bottom: -10px;
}