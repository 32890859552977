@use '..' as able;

.able-IconButton {
  @include able.IconButton();

  &--WithBadge {
    @include able.IconButton($badge: true);
  }

  &--Big {
    @include able.IconButton($size: 'Big');

    &--WithBadge {
      @include able.IconButton($badge: true, $size: 'Big');
    }
  }

  &__Destructive {
    @include able.IconButton($variant: 'Destructive');

    &--Big {
      @include able.IconButton($variant: 'Destructive', $size: 'Big');
    }
  }
}
