@use '..' as able;

.able-MessageGlobal {
  &--Attention {
    @include able.MessageGlobal(Attention);
  }

  &--Warning {
    @include able.MessageGlobal(Warning);
  }

  &--Neutral {
    @include able.MessageGlobal(Neutral);
  }
}
