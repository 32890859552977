.throughput-content-detail {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.left-content {
    margin-bottom: 40px;
}

.no-result-found {
    height: 30px;
    color: rgb(65, 65, 65);
    font-size: 24px;
    font-family: TelstraAkkurat-Bold;
    font-weight: bold;
    letter-spacing: -0.5px;
    line-height: 30px;
    margin: 25px 0 10px 0;
}

.no-result-message {
    height: auto;
    color: rgb(65, 65, 65);
    font-size: 16px;
    font-family: TelstraAkkurat-Light;
    font-weight: 300;
    text-align: center;
    letter-spacing: -0.13px;
    margin-bottom: 10px;
    padding: 0px 10px;
}

.picto-svg-class {
    width: 36px;
    height: 35.93px;
    top: 428.5px;
    left: 299px;
}

.no-result-div {
    text-align: center;
    align-items: center;
    margin-top: 20px;
}

.picto-sizing {
    width: 104px;
    height: 104px;
}

.loading{
    height: 419px;
}