.performance-card {
    background: rgb(255, 255, 255);
    border-radius: 2px;
    border: 1px solid rgb(210, 210, 210);
    height: 274px;
    flex: 1 0 300px;
    margin: 35px 0px;
}

.performance-card-title {
    height: 34px;
    color: rgb(40, 40, 40);
    font-size: 24px;
    font-family: TelstraAkkuratBold;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 34px;
    margin: 16px;
}

.performance-with-nops-value {
    color: rgb(40, 40, 40);
    font-family: TelstraAkkuratBold;
    font-size: 36px;
    font-weight: bold;
    height: 50px;
    letter-spacing: -1px;
    text-align: center;
    align-items: center;
    margin-top: 50px;
}

.performance-without-nops-value {
    height: 32px;
    color: rgb(65, 65, 65);
    font-size: 16px;
    font-family: TelstraAkkuratRegular;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0.15px;
    margin-top: 29px;
}

.performance-arrow-icon {
    margin-left: -24px;
}