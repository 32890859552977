@use '..' as able;

.able-MessagePromotion {
  &--Deal {
    @include able.MessagePromotion;

    &--On-Surface {
      @include able.MessagePromotion($on-surface: true);
    }
  }

  &--EarnPoints {
    @include able.MessagePromotion;

    &--On-Surface {
      @include able.MessagePromotion($on-surface: true);
    }
  }
}
