.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.content-box {
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: 0px 2px 5px grey;
	padding: 2em;
}