.greeting-msg {
    color: #282828;
    font-family: "TelstraAkkuratBold";
    font-size: 40px;
    font-weight: bold;
    letter-spacing: -0.25px;
    line-height: 52px;
    @media (max-width: 768px) {
        font-size: 34px,
    }
}

.tenancy-name {
    color: rgb(65, 65, 65);
    font-family: "TelstraAkkuratRegular";
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.15px;
    line-height: 24px;
}

@keyframes placeHolderShimmer {
    0% {
      background-position: -800px 0
    }
    100% {
      background-position: 800px 0
    }
  }

.animated-background {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-color: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
    background-size: 800px 104px;
    height: 16px;
    width: 100px;
    margin: 0.5em 0;
    position: relative;
    margin: 0;
  }