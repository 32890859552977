@use '..' as able;

.able-MessageLifestyle {
  &__Communication {
    @include able.MessageLifestyle(Communication);
  }

  &__Entertainment {
    @include able.MessageLifestyle(Entertainment);
  }

  &__Social {
    @include able.MessageLifestyle(Social);
  }
}
