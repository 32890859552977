@use '..' as able;

.able-SectionHeader--Level1 {
  @include able.SectionHeader(Level1);
}

.able-SectionHeader--Level2 {
  @include able.SectionHeader(Level2);
}

.able-SectionHeader--Level1--WithActionOnly {
  @include able.SectionHeader(Level1--WithActionOnly);
}

.able-SectionHeader--Level2--WithActionOnly {
  @include able.SectionHeader(Level2--WithActionOnly);
}

.able-SectionHeader--Icon-Tertiary {
  @include able.setSectionHeaderIconColourCustomProperties(
    $icon-colour-override: Tertiary
  );
}

.able-SectionHeader--Icon-Quaternary {
  @include able.setSectionHeaderIconColourCustomProperties(
    $icon-colour-override: Quaternary
  );
}
