@use '..' as able;

/*
  Breadcrumb styles
*/

.able-Breadcrumbs {
  @include able.Breadcrumbs;
}

.able-Breadcrumbs--white-bg {
  @include able.Breadcrumbs(true);
}

// Temporary, this will be updated in able/web
[aria-label='Breadcrumbs'].able-Breadcrumbs--expanded ol {
  max-width: 100%;
}
