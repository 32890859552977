.card {
    width: 328px;
    height: 353px;
    padding: 16px 23px 16px 23px;
    border-radius: 2px;
    border: 1px solid rgb(210, 210, 210);
    margin-bottom: 39px;
    min-width: 277px;
}

.card-upper-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.upper-content-flex {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
}

.card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-txt {
    color: rgb(40, 40, 40);
    font-family: "TelstraAkkuratBold";
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 34px;
}

.action-btn {
    color: rgb(0, 98, 218);
    font-family: "TelstraAkkuratRegular";
    font-size: 16px !important;
    font-weight: normal;
    letter-spacing: 0px !important;
    line-height: 24px !important;
    text-decoration: none;
    border-bottom: 2px solid rgb(0, 98, 218);
    margin-bottom: 8px !important;
}

.service-count {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "TelstraAkkurat";
    color: rgb(65, 65, 65);
    font-size: 16px;
    letter-spacing: 0.15px;
    min-width: 269px;
    border-bottom: 1px solid lightgray;
    height: 56px;
    padding: 0 1.25em 0 0.25em;
    margin-top: 21px;
    text-decoration: none;
}