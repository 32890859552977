@use '..' as able;

.able-Select {
  &--Auto {
    @include able.Select();
  }

  &--Default {
    @include able.Select(Default);
  }

  &--Short {
    @include able.Select(Short);
  }

  &--Long {
    @include able.Select(Long);
  }
}
