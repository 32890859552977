@use '..' as able;
@use 'sass:map';

$TextStyles: able.$TextStyles;

.able-text-style {
  @each $name, $properties in $TextStyles {
    $colours: map.get($properties, 'colours');
    $allowsCentre: map.get($properties, 'allowsCentre');

    @if map.has-key($colours, default) {
      &--#{$name} {
        @include able.TextStyle($name);

        &--with-list {
          @include able.TextStyle($name, $include-lists: true);
        }
      }

      @if $allowsCentre {
        &--#{$name}Center {
          @include able.TextStyle($name, $alignment: 'Center');

          &--with-list {
            @include able.TextStyle(
              $name,
              $include-lists: true,
              $alignment: 'Center'
            );
          }
        }
      }
    }

    @each $colour, $value in $colours {
      @if $colour != 'default' {
        &--#{$name}#{$colour} {
          @include able.TextStyle($name, $colour: $colour);

          &--with-list {
            @include able.TextStyle(
              $name,
              $include-lists: true,
              $colour: $colour
            );
          }
        }

        @if $allowsCentre {
          &--#{$name}#{$colour}Center {
            @include able.TextStyle(
              $name,
              $colour: $colour,
              $alignment: 'Center'
            );

            &--with-list {
              @include able.TextStyle(
                $name,
                $include-lists: true,
                $colour: $colour,
                $alignment: 'Center'
              );
            }
          }
        }
      }
    }
  }
}

.eg-TextStyle > .able-text-style--CallOutInverted {
  background-color: able.colour(materialBaseBrandTertiary);
}

.able-text-style--deprecation-warning {
  color: able.colour(destructiveForegroundNormal);
}
