@use '..' as able;

.able-ActionList--Simple {
  @include able.ActionList(Simple);

  &--top-divider {
    @include able.ActionList(Simple, true);
  }
}

.able-ActionList--Detailed {
  @include able.ActionList(Detailed);

  &--top-divider {
    @include able.ActionList(Detailed, true);
  }
}

.able-ActionList--Hero {
  @include able.ActionList(Hero);

  &--top-divider {
    @include able.ActionList(Hero, true);
  }
}
