@use '..' as able;

@each $name, $surfaceConfig in able.$surface-configuration {
  .able-Surface--#{$name} {
    @include able.Surface($name);
  }

  @each $corner-radius-alias, $size in able.$cornerRadius-sizes {
    @if $name != SurfaceFlat and $corner-radius-alias != cornerRadiusNone {
      .able-Surface--#{$name}--#{$corner-radius-alias}--materialBasePrimary {
        @include able.Surface(
          $surface-name: $name,
          $corner-radius: $corner-radius-alias
        );
      }
    }
    @if $name == SurfaceFlat {
      @each $background in able.$allowedBackgroundColours {
        .able-Surface--#{$name}--#{$corner-radius-alias}--#{$background} {
          @include able.Surface(
            $surface-name: $name,
            $corner-radius: $corner-radius-alias,
            $background: $background
          );
        }
      }
    }
  }

  @if $name != SurfaceTop and $name != SurfaceFlat {
    .able-Surface--#{$name}--interactive {
      @include able.Surface($surface-name: $name, $interactive: true);
    }

    @each $corner-radius-alias, $size in able.$cornerRadius-sizes {
      @if $corner-radius-alias != cornerRadiusNone {
        .able-Surface--#{$name}--#{$corner-radius-alias}--interactive {
          @include able.Surface(
            $surface-name: $name,
            $interactive: true,
            $corner-radius: $corner-radius-alias
          );
        }
      }
    }
  }
}
