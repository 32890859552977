.justify-content {
    text-align: justify !important;
    font-family: "TelstraAkkuratLight";
    font-size: 14px;
    margin-bottom: 30px;

    li::marker {
        content: "•   ";
        margin-right: 10px;
    }
}

.info {
    margin-top: 35px;
    color: rgb(65, 65, 65);
    font-family: "TelstraAkkuratBold";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.1px;
}